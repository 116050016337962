import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { VscDashboard } from "react-icons/vsc";
import { FaBlog, FaBlogger, FaUmbrellaBeach, FaUser } from "react-icons/fa";
import { BiCommentDetail, BiImageAdd, BiPhotoAlbum } from "react-icons/bi";
import BeachPage from "../page/app/beach";
import BeachIDWisePage from "../page/app/beach/Id";
import Users from "../page/app/user";
import UserIdPage from "../page/app/user/Id";
import {
  MdCategory,
  MdContactPage,
  MdContactSupport,
  MdDocumentScanner,
  MdEmail,
  MdLocationSearching,
  MdMap,
  MdOutlineBeachAccess,
  MdSettings,
  MdSettingsApplications,
  MdSettingsSuggest,
} from "react-icons/md";
import { GiSharkFin } from "react-icons/gi";
import { TbArrowForwardUp } from "react-icons/tb";
import Setting from "../page/app/setting";
import { BsImage, BsList, BsTags } from "react-icons/bs";
import Listicle from "../page/app/listicles";
import Location from "../page/app/location";
import ListclesIdWise from "../page/app/listicles/Id";
import CategoryPage from "../page/app/category";
import CountryLocation from "../page/app/location/country";
import StateLocation from "../page/app/location/country/state";
import CityLocation from "../page/app/location/country/state/city";
import TagPage from "../page/app/tag";
import BlogPage from "../page/app/blog";
import AllPhotosPage from "../page/app/allPhotos";
import RedirectionPage from "../page/app/redirect";
import ContactUs from "../page/app/contactInfo/contactUs";
import SubscribedEmail from "../page/app/contactInfo/subscribe";
import IslandLocation from "../page/app/location/country/island";
import MapPage from "../page/app/map";
import UserPhotos from "../page/app/userPhotos";
import Log from "../page/app/log";
import TaggedBeachPage from "../page/app/beach/tagbeach";
import TaggedSingleBeach from "../page/app/beach/tagbeach/Id";
import Suggestions from "../page/app/suggestions";
import WithoutCoverBeachPage from "../page/app/allPhotos/WithoutCoverBeaches";
import SharkSpecies from "../page/app/shark/category/SharkSpecies";
import SharksAttacks from "../page/app/shark";
import { LiaClipboardListSolid } from "react-icons/lia";
export const loginRoot = "/";

export const appRoot = "/app";

export const ROUTES = {
  app: "/app",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "dashboard",
  BEACH: "allbeach",
  TAG_BEACH: "tagbeach",
  LISTICLES: "listicles",
  USERS: "users",
  WITHOUT_COVER_PHOTOS: "withoutCoverPhotos",
  USERS_DETAIL: "users/:id",
  SETTINGS: "settings",
  CATEGORY: "category",
  TAG: "tag",
  BLOG: "blog",
  SHARK: "shark",
  CONTACT_INFO: "contactInfo",
  CONTACTUS: "contactUs",
  SUBSCRIBE: "subscribe",
  MAP: "map",
  LOG: "log",
  ALL_PHOTOS: "allphotos",
  USER_PHOTOS: "userPhotos",
  REDIRECT_URL: "redicturl",
  LOCATION: "location",
  SUGGESTIONS: "suggestion",
};

export const ROLES = {
  ADMIN: "admin",
  DATA: "data",
  IMAGE: "image-person",
  INTERN: "intern",
  SEO: "seo",
  SUPER_ADMIN: "super-admin",
  SUPERUSER: "super-user",
  // USER: "user",
  WRITER: "writer",
  DEFAULT: "default",
};

const LOGIN_ROUTES = [
  {
    index: true,
    element: <LogIn />,
  },
];

const ALL_ROUTES = (appProps) => [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout {...appProps} />,
    Role: [
      ROLES.ADMIN,
      ROLES.DATA,
      ROLES.IMAGE,
      ROLES.INTERN,
      ROLES.SEO,
      ROLES.SUPER_ADMIN,
      ROLES.SUPERUSER,
      ROLES.WRITER,
      ROLES.DEFAULT,
    ],
    // label: "Dashboard ",

    children: [
      {
        index: true,
        element: (
          <Navigate
            to={`${ROUTES.DASHBOARD}`}
            {...appProps}
            appProps={appProps}
          />
        ),
      },
      {
        path: `${ROUTES.DASHBOARD}`,
        element: <Dashboard {...appProps} appProps={appProps} />,
        icon: <VscDashboard />,
        label: "Dashboard",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          ROLES.WRITER,
          ROLES.DEFAULT,
        ],
      },
      {
        path: `${ROUTES.BEACH}`,
        icon: <FaUmbrellaBeach />,
        label: "All Beaches",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <BeachPage {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },

          {
            path: `:country/:state/:city/:name`,
            element: <BeachIDWisePage {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.TAG_BEACH}`,
        icon: <MdOutlineBeachAccess />,
        label: "Tagged Beach",
        Role: [
          // ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          // ROLES.SEO,
          // ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <TaggedBeachPage {...appProps} />,
            Role: [
              // ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              ROLES.INTERN,
              // ROLES.SEO,
              // ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
          {
            path: `:country/:state/:city/:name`,
            element: <TaggedSingleBeach {...appProps} />,
            Role: [
              // ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              ROLES.INTERN,
              // ROLES.SEO,
              // ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.USERS}`,

        icon: <FaUser />,
        label: "Users",
        Role: [
          // ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          // ROLES.INTERN,
          // ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          // ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <Users {...appProps} />,
            Role: [
              // ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `:userId`,
            element: <UserIdPage {...appProps} />,
            Role: [
              // ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.LISTICLES}`,
        icon: <BsList />,
        label: "All Listicles",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <Listicle {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
          {
            path: `:id`,
            element: <ListclesIdWise {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.LOCATION}`,
        icon: <MdLocationSearching />,
        label: "All Locations",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          // ROLES.INTERN,
          // ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          // ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <Location {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `island`,
            // element: <Location {...appProps} />,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
            children: [
              {
                path: `:islandSlug`,
                element: <IslandLocation />,
                Role: [
                  ROLES.ADMIN,
                  ROLES.DATA,
                  // ROLES.IMAGE,
                  // ROLES.INTERN,
                  // ROLES.SEO,
                  ROLES.SUPER_ADMIN,
                  ROLES.SUPERUSER,
                  // ROLES.WRITER,
                ],
              },
            ],
          },
          {
            path: `:countrySlug`,
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
            children: [
              {
                index: true,
                element: <CountryLocation />,
                Role: [
                  ROLES.ADMIN,
                  ROLES.DATA,
                  // ROLES.IMAGE,
                  // ROLES.INTERN,
                  // ROLES.SEO,
                  ROLES.SUPER_ADMIN,
                  ROLES.SUPERUSER,
                  // ROLES.WRITER,
                ],
              },
              {
                path: `:stateSlug`,
                Role: [
                  ROLES.ADMIN,
                  ROLES.DATA,
                  // ROLES.IMAGE,
                  // ROLES.INTERN,
                  // ROLES.SEO,
                  ROLES.SUPER_ADMIN,
                  ROLES.SUPERUSER,
                  // ROLES.WRITER,
                ],
                children: [
                  {
                    index: true,
                    element: <StateLocation />,
                    Role: [
                      ROLES.ADMIN,
                      ROLES.DATA,
                      // ROLES.IMAGE,
                      // ROLES.INTERN,
                      // ROLES.SEO,
                      ROLES.SUPER_ADMIN,
                      ROLES.SUPERUSER,
                      // ROLES.WRITER,
                    ],
                  },
                  {
                    path: `:citySlug`,
                    element: <CityLocation />,
                    Role: [
                      ROLES.ADMIN,
                      ROLES.DATA,
                      // ROLES.IMAGE,
                      // ROLES.INTERN,
                      // ROLES.SEO,
                      ROLES.SUPER_ADMIN,
                      ROLES.SUPERUSER,
                      // ROLES.WRITER,
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: `${ROUTES.ALL_PHOTOS}`,
        // element: <BlogPage {...appProps} appProps={appProps} />,
        icon: <BiPhotoAlbum />,
        label: "Photos",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            // path: `${ROUTES.ALL_PHOTOS}`,
            index: true,
            element: <AllPhotosPage {...appProps} appProps={appProps} />,
            icon: <BsImage />,
            label: "All Photos",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.USER_PHOTOS}`,
            element: <UserPhotos {...appProps} appProps={appProps} />,
            icon: <BiImageAdd />,
            label: "UnApproved Photos",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.WITHOUT_COVER_PHOTOS}`,
            element: (
              <WithoutCoverBeachPage {...appProps} appProps={appProps} />
            ),
            icon: <BiImageAdd />,
            label: "Without Cover Photos",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
        ],
      },

      {
        path: `${ROUTES.REDIRECT_URL}`,
        element: <RedirectionPage {...appProps} appProps={appProps} />,
        icon: <TbArrowForwardUp />,
        label: "Redirect Url",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
      },

      {
        path: `${ROUTES.BLOG}`,
        // element: <BlogPage {...appProps} appProps={appProps} />,
        icon: <FaBlog />,
        label: "Blog",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <BlogPage {...appProps} appProps={appProps} />,
            icon: <BiCommentDetail />,
            label: "All Blog",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.CATEGORY}`,
            element: <CategoryPage {...appProps} appProps={appProps} />,
            icon: <MdCategory />,
            label: "Category",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.TAG}`,
            element: <TagPage {...appProps} appProps={appProps} />,
            icon: <BsTags />,
            label: "Tag",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.SHARK}`,
        // element: <BlogPage {...appProps} appProps={appProps} />,
        icon: <GiSharkFin />,
        label: "Sharks",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          ROLES.WRITER,
        ],
        children: [
          {
            index: true,
            element: <SharksAttacks {...appProps} appProps={appProps} />,
            icon: <LiaClipboardListSolid />,
            label: "Shark Attacks",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.CATEGORY}`,
            element: <SharkSpecies {...appProps} appProps={appProps} />,
            icon: <MdCategory />,
            label: "Shark Species",
            Role: [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              ROLES.WRITER,
            ],
          },
         
        ],
      },
      { 
        path: `${ROUTES.CONTACT_INFO}`,
        // element: <BlogPage {...appProps} appProps={appProps} />,
        icon: <MdContactSupport />,
        label: "Contact Info",
        Role: [
          ROLES.ADMIN,
          // ROLES.DATA,
          // ROLES.IMAGE,
          // ROLES.INTERN,
          // ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          // ROLES.WRITER,
        ],
        children: [
          {
            path: `${ROUTES.CONTACTUS}`,
            element: <ContactUs {...appProps} appProps={appProps} />,
            icon: <MdContactPage />,
            label: "Contact Us",
            Role: [
              ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.SUBSCRIBE}`,
            element: <SubscribedEmail {...appProps} appProps={appProps} />,
            icon: <MdEmail />,
            label: "Subscribed Email",
            Role: [
              ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
        ],
      },
      {
        path: `${ROUTES.MAP}`,
        element: <MapPage {...appProps} appProps={appProps} />,
        icon: <MdMap />,
        label: "Map",
        Role: [
          ROLES.ADMIN,
          ROLES.DATA,
          // ROLES.IMAGE,
          ROLES.INTERN,
          // ROLES.SEO,
          ROLES.SUPER_ADMIN,
          ROLES.SUPERUSER,
          // ROLES.WRITER,
        ],
      },
      {
        path: `${ROUTES.SETTINGS}`,
        // element: <BlogPage {...appProps} appProps={appProps} />,
        icon: <MdSettingsApplications />,
        label: "Setting",
        Role: [
          // ROLES.ADMIN,
          // ROLES.DATA,
          // // ROLES.IMAGE,
          // ROLES.INTERN,
          // ROLES.SEO,
          ROLES.SUPER_ADMIN,
          // ROLES.SUPERUSER,
          // ROLES.WRITER,
        ],
        children: [
          {
            // path: `${ROUTES.SETTINGS}`,
            index: true,
            element: <Setting {...appProps} appProps={appProps} />,
            icon: <MdSettings />,
            label: "Advanced Setting",
            Role: [
              // ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.SUGGESTIONS}`,
            element: <Suggestions {...appProps} appProps={appProps} />,
            icon: <MdSettingsSuggest />,
            label: "Suggestions",
            Role: [
              // ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
          {
            path: `${ROUTES.LOG}`,
            element: <Log {...appProps} appProps={appProps} />,
            icon: <MdDocumentScanner />,
            label: "Log",
            Role: [
              // ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/error",
    element: <Error {...appProps} />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize {...appProps} />,
  },
  {
    path: "*",
    element: <Navigate to="/error" {...appProps} />,
  },
];

export default ALL_ROUTES;
