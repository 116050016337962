import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Progress,
  Select,
  Switch,
  notification,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import moment from "moment/moment";
import { FaEye } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import AddBeachImage from "../../component/common/Beach/Add-Beach-Image";
export const weights = {
  imageElements: 32,
  videoElements: 18,
  basicDetailElements: 2,
  descriptionElements: 4,
};
export const ROLES = {
  ADMIN: "admin",
  DATA: "data",
  IMAGE: "image-person",
  INTERN: "intern",
  SEO: "seo",
  SUPER_ADMIN: "super-admin",
  SUPERUSER: "super-user",
  // USER: "user",
  WRITER: "writer",
};
export const ROLES_ARRAY = [
  {
    id: "admin",
    value: "admin",
    label: "Admin",
    text: "Admin",
  },
  {
    id: "data",
    value: "data",
    label: "Data",
    text: "Data",
  },
  {
    id: "image-person",
    value: "image-person",
    label: "Image person",
    text: "Image person",
  },
  {
    id: "intern",
    value: "intern",
    label: "Intern",
    text: "Intern",
  },
  {
    id: "seo",
    value: "seo",
    label: "SEO",
    text: "SEO",
  },
  {
    id: "super-user",
    value: "super-user",
    label: "Super User",
    text: "Super User",
  },
  {
    id: "super-admin",
    value: "super-admin",
    label: "Super Admin",
    text: "Super Admin",
  },
  // {
  //   id: "user",
  //   value: "user",
  //   label: "User",
  //   text: "User",
  // },
  {
    id: "writer",
    value: "writer",
    label: "Writer",
    text: "Writer",
  },
];
export const REDIRECT_ARRAY = [
  {
    id: "Temporary-307",
    label: "Temporary Redirect (307)",
    text: "Temporary Redirect (307)",
    value: "Temporary-307",
  },
  {
    id: "Temporary-308",
    label: "Temporary Redirect (308)",
    text: "Temporary Redirect (308)",
    value: "Temporary-308",
  },
  {
    id: "Permanent",
    label: "Permanent Redirect",
    text: "Permanent Redirect",
    value: "Permanent",
  },
];

export const ActionAssignArray = [
  {
    id: "beach-main",
    label: "Beach Main Details",
    text: "Beach Main Details",
    value: "beach-main",
  },
  {
    id: "beach-basic-details",
    label: "Basic Details",
    text: "Basic Details",
    value: "basic-details",
  },
  {
    id: "beach-category",
    label: "Data Points",
    text: "Data Points",
    value: "beach-category",
  },
  {
    id: "beach-description",
    label: "Summary",
    text: "Summary",
    value: "beach-description",
  },
  {
    id: "image",
    label: "Images",
    text: "Images",
    value: "images",
  },
  // {
  //   id: "cover-images",
  //   label: "Cover Image",
  //   text: "Cover Image",
  //   value: "cover-images",
  // },
  {
    id: "beach-seo",
    label: "SEO [ Setting ]",
    text: "SEO [ Setting ]",
    value: "seo",
  },
];
export const sharkFamilies = [
  {
    id: "SharkFamily-1",
    label: "Scyliorhinidae",
    text: "Scyliorhinidae",
    value: "Scyliorhinidae",
  },
  {
    id: "SharkFamily-2",
    label: "Cetorhinidae",
    text: "Cetorhinidae",
    value: "Cetorhinidae",
  },
  {
    id: "SharkFamily-3",
    label: "Hexanchidae",
    text: "Hexanchidae",
    value: "Hexanchidae",
  },
  {
    id: "SharkFamily-4",
    label: "Alopiidae",
    text: "Alopiidae",
    value: "Alopiidae",
  },
  {
    id: "SharkFamily-5",
    label: "Carcharhinidae",
    text: "Carcharhinidae",
    value: "Carcharhinidae",
  },
  {
    id: "SharkFamily-6",
    label: "Squalidae",
    text: "Squalidae",
    value: "Squalidae",
  },
  {
    id: "SharkFamily-7",
    label: "Heterodontidae",
    text: "Heterodontidae",
    value: "Heterodontidae",
  },
  {
    id: "SharkFamily-8",
    label: "Chlamydoselachidae",
    text: "Chlamydoselachidae",
    value: "Chlamydoselachidae",
  },
  {
    id: "SharkFamily-9",
    label: "Mitsukurinidae",
    text: "Mitsukurinidae",
    value: "Mitsukurinidae",
  },
  {
    id: "SharkFamily-10",
    label: "Proscylliidae",
    text: "Proscylliidae",
    value: "Proscylliidae",
  },
  {
    id: "SharkFamily-11",
    label: "Sphyrnidae",
    text: "Sphyrnidae",
    value: "Sphyrnidae",
  },
  {
    id: "SharkFamily-12",
    label: "Lamnidae",
    text: "Lamnidae",
    value: "Lamnidae",
  },
  {
    id: "SharkFamily-13",
    label: "Triakidae",
    text: "Triakidae",
    value: "Triakidae",
  },
  {
    id: "SharkFamily-14",
    label: "Squatinidae",
    text: "Squatinidae",
    value: "Squatinidae",
  },
  {
    id: "SharkFamily-15",
    label: "Pristiophoridae",
    text: "Pristiophoridae",
    value: "Pristiophoridae",
  },
  {
    id: "SharkFamily-16",
    label: "Parascylliidae",
    text: "Parascylliidae",
    value: "Parascylliidae",
  },
  {
    id: "SharkFamily-17",
    label: "Ginglymostomatidae",
    text: "Ginglymostomatidae",
    value: "Ginglymostomatidae",
  },
  {
    id: "SharkFamily-18",
    label: "Orectolobidae",
    text: "Orectolobidae",
    value: "Orectolobidae",
  },
  {
    id: "SharkFamily-19",
    label: "Hemigaleidae",
    text: "Hemigaleidae",
    value: "Hemigaleidae",
  },
  {
    id: "SharkFamily-20",
    label: "Rhincodontidae",
    text: "Rhincodontidae",
    value: "Rhincodontidae",
  },
  {
    id: "SharkFamily-21",
    label: "Stegostomatidae",
    text: "Stegostomatidae",
    value: "Stegostomatidae",
  },
];
export const sharkTypes = [
  { id: 1, label: 'Unprovoked', value: 'unprovoked' },
  { id: 2, label: 'Unknown', value: 'unknown' },
  { id: 3, label: 'Boat', value: 'boat' },
  { id: 4, label: 'Invalid', value: 'invalid' },
  { id: 5, label: 'Provoked', value: 'provoked' },
  { id: 6, label: 'Questionable', value: 'questionable' },
  { id: 7, label: 'Sea Disaster', value: 'sea_disaster' },
  { id: 8, label: 'Unverified', value: 'unverified' },
  { id: 9, label: 'Under Investigation', value: 'under_investigation' },
  { id: 10, label: 'Watercraft', value: 'watercraft' } 
];
export const activities = [
  { name: 'Adrift', value: 'adrift' },
  { name: 'Bathing', value: 'bathing' },
  { name: 'Boating', value: 'boating' },
  { name: 'Body Surfing', value: 'body_surfing' },
  { name: 'Boogie Boarding', value: 'boogie_boarding' },
  { name: 'Canoeing', value: 'canoeing' },
  { name: 'Cleaning', value: 'cleaning' },
  { name: 'Dangling Feet in Water', value: 'dangling_feet_in_water' },
  { name: 'Diving', value: 'diving' },
  { name: 'Driving', value: 'driving' },
  { name: 'Equipment Testing', value: 'equipment_testing' },
  { name: 'Fishing', value: 'fishing' },
  { name: 'Floating', value: 'floating' },
  { name: 'Foilboarding', value: 'foilboarding' },
  { name: 'Horse Riding', value: 'horse_riding' },
  { name: 'Jetskiing', value: 'jetskiing' },
  { name: 'Kayaking', value: 'kayaking' },
  { name: 'Kiteboarding/Kitesurfing/Kite Foiling', value: 'kiteboarding_kitesurfing_kite_foiling' },
  { name: 'Lifeguarding/Lifesaving', value: 'lifeguarding_lifesaving' },
  { name: 'Murder', value: 'murder' },
  { name: 'Natural Disaster', value: 'natural_disaster' },
  { name: 'Observing Marine Life', value: 'observing_marine_life' },
  { name: 'Opihi Picking', value: 'opihi_picking' },
  { name: 'Paddling', value: 'paddling' },
  { name: 'Paddleboarding', value: 'paddleboarding' },
  { name: 'Paddleskiing', value: 'paddleskiing' },
  { name: 'Parasailing', value: 'parasailing' },
  { name: 'Plane Crash', value: 'plane_crash' },
  { name: 'Playing on Surf or Shore', value: 'playing_on_surf_or_shore' },
  { name: 'Rowing', value: 'rowing' },
  { name: 'Sculling', value: 'sculling' },
  { name: 'Shark Activities', value: 'shark_activities' },
  { name: 'Ship Activities', value: 'ship_activities' },
  { name: 'Shipwreck', value: 'shipwreck' },
  { name: 'Skimboarding', value: 'skimboarding' },
  { name: 'Snorkeling', value: 'snorkeling' },
  { name: 'Spearfishing', value: 'spearfishing' },
  { name: 'Standing', value: 'standing' },
  { name: 'Suicide', value: 'suicide' },
  { name: 'Surf-skiing', value: 'surf_skiing' },
  { name: 'Surfing', value: 'surfing' },
  { name: 'Swimming', value: 'swimming' },
  { name: 'Unknown', value: 'unknown' },
  { name: 'Wading', value: 'wading' },
  { name: 'Wakeboarding', value: 'wakeboarding' },
  { name: 'Walking', value: 'walking' },
  { name: 'Watercraft', value: 'watercraft' },
  { name: 'Water-skiing', value: 'water_skiing' },
  { name: 'Went Overboard', value: 'went_overboard' },
  { name: 'Wind Surfing', value: 'wind_surfing' },
  { name: 'Wing Foiling', value: 'wing_foiling' },
  { name: 'Yachting', value: 'yachting' },
  { name: 'Sailing', value: 'sailing' }
];

// export const ListicleTags = [
//   { id: "access", label: "Access", value: "access" },
//   { id: "address", label: "Address", value: "address" },
//   { id: "state", label: "State", value: "state" },
//   { id: "video", label: "Video", value: "video" },
// ];
export const ActionEnumObject = {
  BEACH_MAIN: "beach-main",
  BEACH_BASIC_DETAILS: "basic-details",
  BEACH_CATEGORY: "beach-category",
  BEACH_DESCRIPTION: "beach-description",
  BEACH_IMAGES: "images",
  BEACH_SEO: "seo",
};
export const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};
export function convertToPST(dateString, formatearray) {
  // Parse the date using moment
  const date = moment(dateString);

  // Manually apply the PST offset of UTC-8
  const pstDate = date.utcOffset(-8);

  // Return the formatted date string in PST
  if (formatearray) {
    return [pstDate.format("YYYY-MM-DD"), pstDate.format("hh:mm A")];
  }
  return pstDate.format("YYYY-MM-DD hh:mm A [PST]");
}
export const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
export const RenderDeleteButton = (value = {}) => {
  const {
    id,
    onClick,
    message = "Are you sure you want to delete this?",
  } = value;
  return (
    <Popconfirm
      title={
        <p className=" flex items-center justify-center">
          <BsQuestionCircle className=" text-red-600 mx-2" /> {message}
        </p>
      }
      onConfirm={() => onClick(id)}
      align="center"
      icon={null}
    >
      <Button type="primary" danger>
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Are you sure you want to delete this beach?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
export const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
export const RenderEditButton = (value = {}) => {
  const { id = null, onClick = () => {} } = value;
  if (!id) {
    return <></>;
  }
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// View Image Button
export const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={<FaEye />}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
export const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
export const RenderViewButton = (value) => {
  const { id, onClick, link } = value;
  return !!!link ? (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined
      // onClick={() => {
      //   // onClick(id);
      // }}
      />
    </Button>
  ) : (
    <Button href={link} type="primary">
      <EyeOutlined
      // onClick={() => {
      //   // onClick(id);
      // }}
      />
    </Button>
  );
};

// Render Images
export const ImageRanders = (value, extraClass) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className={`rounded-full object-cover ${extraClass}`}
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
export const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};
export const RenderInputField = (Upvalue) => {
  const { value, id, onChange } = Upvalue;
  return (
    <div>
      <InputNumber
        // id="rankInput"
        // onChange={() => {
        //   onClick(id, checked);
        // }}
        controls={false}
        value={value}
        // value={value}
        onBlur={(e) => {
          // console.log("Blur Event happended ", parseInt(e.target.value), id);
          onChange({ value: parseInt(e.target.value), id });
          // } else {
          //   notification.error({ message: "Enter valid Integer Value" });
          // }
        }}
        // onPointerOut={(e) => {
        //   console.log(e, "Pointer Out happended ");
        // }}
        // checked={checked}
      />
    </div>
  );
};

// Checkbox render
export const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

const CONSTANTS = {
  GETMe: null,
  TABLE: {
    CATEGORY: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    Suggestions: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Title",
        dataIndex: "name",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Suggestion",
        dataIndex: "suggestion",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "suggestion"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        width: 160,
        filters: [],
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        // render: (v) => v?.name ?? "",
        // sorter: (a, b) => alphanumericSort(a, b, "suggestion"),
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    Beach_Suggestions: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Beach Name",
        dataIndex: "AllBeach",
        render: (v) => v?.name,
        width: 160,
      },
      {
        title: "Topics",
        dataIndex: "topics",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "topics"),
        render: (v) => v?.join(", "),
      },
      {
        title: "Suggestion",
        dataIndex: "suggestion",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "suggestion"),
      },
      {
        title: "User Name",
        // dataIndex: "user",
        dataIndex: "userName",
        width: 160,
        filters: [],
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        // filters: false,
        particularUser: true,

        // render: (v) => v?.name ?? "",
        // sorter: (a, b) => alphanumericSort(a, b, "suggestion"),
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    TAG: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Name",
        dataIndex: "tag",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "tag"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    LOG: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Beach Name",
        dataIndex: "name",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Model Changed",
        dataIndex: "model",
        width: 160,
        render: (v) =>
          ActionAssignArray?.find((one) => one?.id === v)?.label ?? v,
        filters: ActionAssignArray,
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        // sorter: (a, b) => alphanumericSort(a, b, "model"),
      },
      {
        title: "New Data",
        dataIndex: "newData",
        width: 250,
        render: (v) => {
          return <div className=" max-h-20 overflow-y-scroll">{v}</div>;
        },
        // render: (v) => JSON.stringify(JSON.parse(v), null, 2),
      },
      {
        title: "Old Data",
        dataIndex: "oldData",
        width: 250,
        render: (v) => {
          return <div className=" max-h-20 overflow-y-scroll">{v}</div>;
        },
        // render: (v) => JSON.stringify(JSON.parse(v), null, 2),
      },
      {
        title: "Type",
        dataIndex: "type",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        width: 160,
        particularUser: true,
        filters: [],
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        // sorter: (a, b) => alphanumericSort(a, b, "userName"),
      },
      {
        title: "User Email",
        dataIndex: "userEmail",
        // sorter: (a, b) => alphanumericSort(a, b, "userEmail"),
        particularUser: true,

        width: 160,
      },
      {
        title: "User Role",
        dataIndex: "userRole",
        width: 160,
        filters: ROLES_ARRAY,
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        particularUser: true,

        // sorter: (a, b) => alphanumericSort(a, b, "userRole"),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.createdAt).subtract(moment(b.createdAt)),
        // role: [ROLES.ADMIN],

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   // render: (v) =>
      //   //   RenderDeleteButton({
      //   //     ...v,
      //   //     message: "Are you Sure to delete this Category ?",
      //   //   }),
      //   render: RenderDeleteButton,
      //   width: 80,
      //   role: [ROLES.SUPER_ADMIN],
      // },
    ],
    ContactUS: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "firstName"),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "lastName"),
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Message",
        dataIndex: "message",
        width: 400,
        sorter: (a, b) => alphanumericSort(a, b, "message"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    SubscribeEmail: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 20,
      },

      {
        title: "Email",
        dataIndex: "email",
        width: 60,
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },

      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 15,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    REDIRECT_URL: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Old URL",
        dataIndex: "url",
        width: 180,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "url"),
      },
      {
        title: "New URL",
        dataIndex: "originalUrl",
        width: 180,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "originalUrl"),
      },
      {
        title: "Redirect Type",
        dataIndex: "type",
        width: 180,
        filters: REDIRECT_ARRAY,
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        render: (v) => {
          return REDIRECT_ARRAY?.filter((val) => v === val?.id)[0]?.label;
        },
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    BEACH: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Last Updated",
        dataIndex: "updatedAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
        // role: [ROLES.ADMIN],

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },

      {
        title: "Beach Weights",
        dataIndex: "BeachWeight",
        render: (BeachWeight) => {
          const WeightsNew = { ...weights };
          let weightAll = 0;
          // console.log(BeachWeight, {...WeightsNew}, WeightsNew);
          if (BeachWeight && typeof BeachWeight === "object") {
            Object.keys(weights).map((key) => {
              if (BeachWeight[key]) {
                WeightsNew[key] *= BeachWeight[key];
              } else {
                WeightsNew[key] = 0;
              }
            });
            weightAll =
              +WeightsNew?.imageElements +
              +WeightsNew?.videoElements +
              +WeightsNew?.basicDetailElements +
              +WeightsNew?.descriptionElements;
          }
          // const weightAll =
          //   +WeightsNew?.imageElements +
          //   +WeightsNew?.videoElements +
          //   +WeightsNew?.basicDetailElements +
          //   +WeightsNew?.descriptionElements;
          // console.log(BeachWeight, WeightsNew, weights, weightAll);

          return (
            <div className=" p-3">
              <Progress
                // strokeLinecap="butt"
                // type="dashboard"
                size={"small"}
                percent={weightAll ? weightAll?.toFixed(2) : 0}
                strokeColor={
                  weightAll > 75 ? "green" : weightAll > 50 ? "orange" : "red"
                }
              />
            </div>
          );
        },
        width: 180,
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    BEACH_WITHOUT_PHOTOS: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Last Updated",
        dataIndex: "updatedAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
        // role: [ROLES.ADMIN],

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      {
        title: "Cover Photo",
        dataIndex: "coverPhotoAdd",
        width: 180,
        render: (value) => {
          const Refrsher = () => {};
          return (
            <AddBeachImage
              masterRefresh={true}
              beachData={value?.beachData}
              refresher={value?.refresher ?? Refrsher}
            />
          );
        },
      },

      // {
      //   title: "Beach Weights",
      //   dataIndex: "BeachWeight",
      //   render: (BeachWeight) => {
      //     const WeightsNew = { ...weights };
      //     let weightAll = 0;
      //     // console.log(BeachWeight, {...WeightsNew}, WeightsNew);
      //     if (BeachWeight && typeof BeachWeight === "object") {
      //       Object.keys(weights).map((key) => {
      //         if (BeachWeight[key]) {
      //           WeightsNew[key] *= BeachWeight[key];
      //         } else {
      //           WeightsNew[key] = 0;
      //         }
      //       });
      //       weightAll =
      //         +WeightsNew?.imageElements +
      //         +WeightsNew?.videoElements +
      //         +WeightsNew?.basicDetailElements +
      //         +WeightsNew?.descriptionElements;
      //     }
      //     // const weightAll =
      //     //   +WeightsNew?.imageElements +
      //     //   +WeightsNew?.videoElements +
      //     //   +WeightsNew?.basicDetailElements +
      //     //   +WeightsNew?.descriptionElements;
      //     // console.log(BeachWeight, WeightsNew, weights, weightAll);

      //     return (
      //       <div className=" p-3">
      //         <Progress
      //           // strokeLinecap="butt"
      //           // type="dashboard"
      //           size={"small"}
      //           percent={weightAll ? weightAll?.toFixed(2) : 0}
      //           strokeColor={
      //             weightAll > 75 ? "green" : weightAll > 50 ? "orange" : "red"
      //           }
      //         />
      //       </div>
      //     );
      //   },
      //   width: 180,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      //   role: [ROLES.SUPER_ADMIN],
      // },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    USER_TAG_BEACH: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Last Updated",
        dataIndex: "updatedAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
        // role: [ROLES.ADMIN],

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      {
        title: "NOTES",
        dataIndex: "notes",
        width: 160,
        role: [ROLES.INTERN],
      },
      {
        title: "ACTION",
        dataIndex: "action",
        width: 160,
        render: (v) =>
          v
            ?.map((el) => {
              return ActionAssignArray?.find((one) => one?.id === el)?.label;
            })
            ?.join(", "),
        role: [ROLES.INTERN],
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 160,

        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={status.value === "DONE"}
            >
              <Select.Option key={"TODO"}>TODO</Select.Option>
              <Select.Option key={"INPROGRESS"}>INPROGRESS</Select.Option>
              <Select.Option key={"DONE"}>DONE</Select.Option>
            </Select>
          );
        },
        role: [ROLES.INTERN],
      },
      // {
      //   title: "Beach Weights",
      //   dataIndex: "BeachWeight",
      //   render: (BeachWeight) => {
      //     const WeightsNew = { ...weights };
      //     if (BeachWeight && typeof BeachWeight === "object") {
      //       Object.keys(BeachWeight).map((key) => {
      //         WeightsNew[key] *= BeachWeight[key];
      //       });
      //     }
      //     const weightAll =
      //       +WeightsNew?.imageElements +
      //       +WeightsNew?.videoElements +
      //       +WeightsNew?.basicDetailElements +
      //       +WeightsNew?.descriptionElements;

      //     return (
      //       <div className=" p-3">
      //         <Progress
      //           // strokeLinecap="butt"
      //           // type="dashboard"
      //           size={"small"}
      //           percent={weightAll ? weightAll?.toFixed(2) : 0}
      //           strokeColor={
      //             weightAll > 75 ? "green" : weightAll > 50 ? "orange" : "red"
      //           }
      //         />
      //       </div>
      //     );
      //   },
      //   width: 180,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      //   role: [ROLES.SUPER_ADMIN],
      // },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    USERS: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Active",
        dataIndex: "isBlocked",
        width: 140,
        render: RenderToggleButton,
      },
      {
        title: "Role",
        dataIndex: "role",
        width: 140,
        filters: ROLES_ARRAY,
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
        render: (v) => {
          return `${v}`.toUpperCase();
        },
      },

      {
        title: "Join Date",
        dataIndex: "createdAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.createdAt).subtract(moment(b.createdAt)),
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      // },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    USER_BEACH: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      // {
      //   title: "View",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 80,
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "NOTES",
        dataIndex: "notes",
        width: 160,
      },
      {
        title: "ACTION",
        dataIndex: "action",
        width: 160,
        render: (v) =>
          v
            ?.map((el) => {
              return ActionAssignArray?.find((one) => one?.id === el)?.label;
            })
            ?.join(", "),
        // sorter: (a, b) => alphanumericSort(a, b, "action"),
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              // disabled={status.value === "DONE"}
            >
              <Select.Option key={"TODO"}>TODO</Select.Option>
              <Select.Option key={"INPROGRESS"}>INPROGRESS</Select.Option>
              <Select.Option key={"DONE"}>DONE</Select.Option>
            </Select>
          );
        },
      },

      // {
      //   title: "Last Updated",
      //   dataIndex: "updatedAt",
      //   width: 180,
      //   render: (v) => {
      //     const value = convertToPST(v, true);
      //     return (
      //       <>
      //         <p>{value[0]}</p>
      //         <p>{`${value[1]} [PST]`} </p>
      //       </>
      //     );
      //   },
      //   sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
      //   // render: ImageRanders,
      //   // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      // },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      // },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    LISTICLE: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        width: 80,

        render: (v) => ImageRanders(v, "rounded-sm"),
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    LISTICLE_BEACHES: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      // {
      //   title: "Image",
      //   dataIndex: "image",
      //   width: 80,

      //   render: (v) => ImageRanders(v, "rounded-sm"),
      //   // sorter: (a, b) => alphanumericSort(a, b, "name"),
      // },
      // {
      //   title: "View",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 80,
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Index",
        dataIndex: "rankInput",
        width: 120,
        render: RenderInputField,

        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "url Count",
        dataIndex: "urlCount",
        width: 110,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    LOCATION: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      // {
      //   title: "Image",
      //   dataIndex: "image",
      //   width: 80,

      //   render: (v) => ImageRanders(v, "rounded-sm"),
      //   // sorter: (a, b) => alphanumericSort(a, b, "name"),
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      // {
      //   title: "City",
      //   dataIndex: "cityName",
      //   width: 160,

      //   // render: ImageRanders,
      //   // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      // },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Rank",
        dataIndex: "rankInput",
        width: 120,
        render: RenderInputField,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
    ],
    BLOG: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 380,
      },
      {
        title: "Read Time",
        dataIndex: "readTime",
        sorter: (a, b) => alphanumericSort(a, b, "readTime"),
        width: 170,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        // render:RenderTextClick,
        // sorter: (a, b) => alphanumericSort(a, b, "comments"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Index",
        dataIndex: "rankInput",
        width: 120,
        render: RenderInputField,

        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    SHARK_SPECIES: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Shark Family",
        dataIndex: "sharkFamily",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    SHARK_ATTACKS: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Beach Name",
        dataIndex: "beachName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "beachName"),
      },
      {
        title: "Type",
        dataIndex: "type",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "Activity",
        dataIndex: "activity",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "activity"),
      },
      {
        title: "Injury",
        dataIndex: "injury",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "injury"),
      },
      
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    LISTICLE_BEACHES: [
      {
        no: 1,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter a Description",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Urls",
        name: "urls",
        id: "urls",
        type: "multifield",
        placeholder: "Enter a Urls",
        required: true,
        width: 24,
        removeName: "Remove Url",
        addName: "Add Url",
        AllFields: [
          {
            no: 1,
            Label: "url",
            name: "url",
            id: "url",
            type: "url",
            placeholder: "Enter a url",
            required: true,
            width: 24,
          },
        ],
      },
    ],
    CATEGORY: [
      {
        no: 1,
        Label: "Category",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Category",
        required: true,
        width: 24,
      },
    ],
    SUGGESTIONS: [
      {
        no: 1,
        Label: "Title",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Title",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Suggestion",
        name: "suggestion",
        id: "suggestion",
        type: "text",
        placeholder: "Enter a Suggestion",
        required: true,
        width: 24,
      },
    ],
    BEACH_SUGGESTIONS: [
      {
        no: 1,
        Label: "topics",
        name: "topics",
        id: "topics",
        type: "select",
        mode: "multiple",
        option: [
          { id: "access", label: "Access", value: "access" },
          { id: "address", label: "Address", value: "address" },
          { id: "amenities", label: "Amenities", value: "amenities" },
          { id: "beach-map", label: "Beach Map", value: "beach-map" },
          { id: "beach-name", label: "Beach Name", value: "beach-name" },
          { id: "blog", label: "Blog", value: "blog" },
          { id: "city", label: "City", value: "city" },
          { id: "country", label: "Country", value: "country" },
          { id: "description", label: "Description", value: "description" },
          { id: "faq", label: "FAQ", value: "faq" },
          { id: "food", label: "Food", value: "food" },
          { id: "others", label: "Others", value: "others" },
          { id: "rentals", label: "Rentals", value: "rentals" },
          { id: "state", label: "State", value: "state" },
          { id: "video", label: "Video", value: "video" },
        ],
        placeholder: "Select Points to improve",
        // required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "suggestion",
        name: "suggestion",
        id: "suggestion",
        type: "textarea",
        placeholder: "Enter a suggestion",
        // required: true,
        width: 24,
      },
    ],
    TAG: [
      {
        no: 1,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "text",
        placeholder: "Enter a Tag",
        required: true,
        width: 24,
      },
    ],
    REDIRECT_URL: [
      {
        no: 1,
        Label: "Old URL",
        name: "url",
        id: "url",
        type: "text",
        placeholder: "Enter a URL on Which you want to Redirect (Old URL)",
        required: true,
        // pattern: /^\/[a-zA-Z0-9-]+$/,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern: /^\/[a-zA-Z0-9-]+(?:\/[a-zA-Z0-9-]+){0,4}$/,
            message: "Enter valid URL",
          },
        ],
        width: 24,
      },
      {
        no: 2,
        Label: "New URL",
        name: "originalUrl",
        id: "originalUrl",
        type: "text",
        placeholder: "Enter a New URL",
        required: true,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern: /^\/[a-zA-Z0-9-]+(?:\/[a-zA-Z0-9-]+){0,4}$/,
            message: "Enter valid URL",
          },
        ],
        width: 24,
      },
      {
        no: 3,
        Label: "Redirect Type",
        name: "type",
        id: "type",
        type: "select",
        placeholder: "Select a type",
        required: true,
        option: REDIRECT_ARRAY,
        width: 24,
      },
    ],
    ADD_COUNTRY_STATE_CITY: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter name",
        required: true,
        width: 24,
      },
      // {
      //   no: 2,
      //   Label: "Select Country , State and City Selector",
      //   name: "Slector",
      //   id: "selector",
      //   type: "citystatecountry",
      //   required: true,
      //   RequiredObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   VisibleObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   fieldFilter: ["Country"],
      // },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["State", "Island"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["City"],
      },

      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Meta Description",
        required: true,
      },
      {
        no: 3,
        Label: "description",
        name: "summary",
        id: "summary",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter description",
        // required: true,
      },
      {
        no: 4,
        Label: "Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Cover Photo",
        required: false,
      },
      {
        no: 5,
        Label: "Tags",
        name: "tagIds",
        id: "tagIds",
        type: "select",
        mode: "multiple",
        placeholder: "Select tags",
        // required: true,
        option: [],
      },
    ],
    EDIT_COUNTRY_STATE_CITY: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter name",
        required: true,
        width: 24,
      },
      // {
      //   no: 2,
      //   Label: "Select Country , State and City Selector",
      //   name: "Slector",
      //   id: "selector",
      //   type: "citystatecountry",
      //   required: true,
      //   RequiredObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   VisibleObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   fieldFilter: ["Country"],
      // },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["State", "Island"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["City"],
      },
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Meta Description",
        required: true,
      },

      {
        no: 5,
        Label: "Tags",
        name: "tagIds",
        id: "tagIds",
        type: "select",
        mode: "multiple",
        placeholder: "Select tags",
        // required: true,
        option: [],
      },
      // {
      //   no: 4,
      //   Label: "Cover Photo",
      //   name: "image",
      //   id: "image",
      //   type: "file",
      //   placeholder: "Select Cover Photo",
      //   required: false,
      // },
      // {
      //   no: 6,
      //   Label: "Rank",
      //   name: "rank",
      //   id: "rank",
      //   type: "number",
      //   placeholder: "Enter Rank",
      // },
      // {
      //   no: 3,
      //   Label: "description",
      //   name: "summary",
      //   id: "summary",
      //   // type: "richTextarea",
      //   type: "SimpleRichTextArea",
      //   placeholder: "Enter description",
      //   // required: true,
      // },
    ],
    BEACH_USER_RELATION: [
      {
        no: 1,
        Label: "User",
        name: "userId",
        id: "userId",
        type: "select",
        placeholder: "User name",
        option: [],
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Category Name",
        name: "action",
        id: "action",
        type: "select",
        multiple: true,
        mode: "multiple",

        placeholder: "Category name",
        option: ActionAssignArray,
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: " Notes",
        name: "notes",
        id: "notes",
        type: "textarea",
        placeholder: "Enter Notes for User",
        // required: true,
      },
    ],
    BEACH_USER_RELATION_SINGLE: [
      {
        no: 2,
        Label: "Category Name",
        name: "action",
        id: "action",
        type: "select",
        placeholder: "Category name",
        mode: "multiple",
        option: ActionAssignArray,
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: " Notes",
        name: "notes",
        id: "notes",
        type: "textarea",
        placeholder: "Enter Notes for User",
        // required: true,
      },
    ],
    BEACHMAIN_ADD: [
      {
        no: 1,
        Label: "Beach Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Beach Name",
        required: true,
      },
      {
        no: 2,
        Label: "Country , State and City Selector",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
      },
      {
        no: 3,
        Label: "Alternate Name",
        name: "altName",
        id: "altName",
        type: "text",
        placeholder: "Enter Alternate Name",
        // required: true,
      },
      {
        no: 4,
        Label: "latitude",
        name: "latitude",
        id: "latitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -90 &&
                parseFloat(value) <= 90
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid latitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter latitude",
        // required: true,
      },
      {
        no: 5,
        Label: "longitude",
        name: "longitude",
        id: "longitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -180 &&
                parseFloat(value) <= 180
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid longitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter longitude",
        // required: true,
      },
      {
        no: 6,
        Label: "rating out of 100",
        name: "rating100",
        id: "rating100",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseInt(value) <= 100 &&
                parseFloat(value) >= 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid rating"));
            },
          }),
        ],
        width: 8,
        type: "number",
        placeholder: "Enter rating",
        // required: true,
      },
      {
        no: 7,
        Label: "video",
        name: "droneVideo",
        id: "droneVideo",
        width: 24,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern:
              /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
            message: "Enter valid drone Video url",
          },
        ],
        type: "text",
        placeholder: "Enter Drone Video",
        required: false,
      },
      {
        no: 2,
        Label: "Beach Cams Title",
        name: "beachCamTitle",
        id: "beachCamTitle",
        width: 12,
        type: "text",
        placeholder: "Enter Beach Cams Title",
        // required: true,
      },
      {
        no: 2,
        Label: "Beach Cams Link",
        name: "beachCamLink",
        id: "beachCamLink",
        width: 12,
        type: "text",
        placeholder: "Enter Beach Cams Link",
        // required: true,
      },
      // {
      //   no: 8,
      //   Label: "Address",
      //   name: "address",
      //   id: "address",
      //   // width: 24,
      //   type: "text",
      //   placeholder: "Enter Address",
      //   // required: true,
      // },
      // {
      //   no: 3,
      //   Label: "top Fifty",
      //   name: "topFifty",
      //   id: "topFifty",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,
      //   required: true,
      // },
      // {
      //   no: 3,
      //   Label: "favorite",
      //   name: "favorite",
      //   id: "favorite",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,

      //   required: true,
      // },
    ],
    BEACH_LISTICLE: [
      {
        no: 1,
        Label: "Listicle Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Listicle Name",
        required: true,
      },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        // required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: false,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["Country"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["State"],
      },
      {
        no: 2,
        Label: "Select Country , State and City",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["City"],
      },
      {
        no: 3,
        Label: "Listicle Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Listicle Cover Photo",
        required: true,
      },
      {
        no: 4,
        Label: "Listicle Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Listicle Description",
        required: true,
      },
      // {
      //   no: 5,
      //   Label: "Tags",
      //   name: "tagIds",
      //   id: "tagIds",
      //   type: "select",
      //   mode: "multiple",
      //   placeholder: "Select tags",
      //   // required: true,
      //   option: [],
      // },
      {
        no: 5,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "autocomplete",
        // mode: "combobox",
        option: [],
        // mode: "multiple",
        // option: ListicleTags,
        placeholder: "Select Tag",
        required: false,
        // width: 24,
      },
    ],
    BEACH_LISTICLE_EDIT: [
      {
        no: 1,
        Label: "Listicle Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Listicle Name",
        required: true,
      },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        // required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: false,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["Country"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["State"],
      },
      {
        no: 2,
        Label: "Select Country , State and City",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        fieldFilter: ["City"],
      },
      {
        no: 3,
        Label: "Listicle Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Listicle Cover Photo",
        // required: true,
      },
      {
        no: 4,
        Label: "Listicle Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Listicle Description",
        required: true,
      },
      // {
      //   no: 5,
      //   Label: "Tags",
      //   name: "tagIds",
      //   id: "tagIds",
      //   type: "select",
      //   mode: "multiple",
      //   placeholder: "Select tags",
      //   // required: true,
      //   option: [],
      // },
      {
        no: 5,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "autocomplete",
        // mode: "autocomplete",
        option: [],
        // mode: "multiple",
        // option: ListicleTags,
        placeholder: "Select Tag",
        required: false,
        // width: 24,
      },
    ],
    BEACHMAIN_EDIT: [
      {
        no: 1,
        Label: "Beach Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Beach Name",
        required: true,
      },
      {
        no: 32,
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        label: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: false,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
          islandId: true,
        },
      },
      {
        no: 1,
        Label: "Alternate Name",
        name: "altName",
        id: "altName",
        type: "text",
        placeholder: "Enter Alternate Name",
        // required: true,
      },
      {
        no: 2,
        Label: "latitude",
        name: "latitude",
        id: "latitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -90 &&
                parseFloat(value) <= 90
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid latitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter latitude",
        // required: true,
      },
      {
        no: 2,
        Label: "longitude",
        name: "longitude",
        id: "longitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -180 &&
                parseFloat(value) <= 180
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid longitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter longitude",
        // required: true,
      },
      {
        no: 4,
        Label: "rating out of 100",
        name: "rating100",
        id: "rating100",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseInt(value) <= 100 &&
                parseFloat(value) >= 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid rating"));
            },
          }),
        ],
        width: 8,
        type: "number",
        placeholder: "Enter rating",
        // required: true,
      },
      {
        no: 3,
        Label: "video",
        name: "droneVideo",
        id: "droneVideo",
        width: 24,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern:
              /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
            message: "Enter valid drone Video url",
          },
        ],
        type: "text",
        placeholder: "Enter Drone Video",
        required: false,
      },
      {
        no: 2,
        Label: "Beach Cams Title",
        name: "beachCamTitle",
        id: "beachCamTitle",
        width: 12,
        type: "text",
        placeholder: "Enter Beach Cams Title",
        // required: true,
      },
      {
        no: 2,
        Label: "Beach Cams Link",
        name: "beachCamLink",
        id: "beachCamLink",
        width: 12,
        type: "text",
        placeholder: "Enter Beach Cams Link",
        // required: true,
      },
      {
        no: 2,
        Label: "Address",
        name: "address",
        id: "address",
        // width: 24,
        type: "text",
        placeholder: "Enter Address",
        // required: true,
      },
      // {
      //   no: 3,
      //   Label: "top Fifty",
      //   name: "topFifty",
      //   id: "topFifty",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,
      //   required: true,
      // },
      // {
      //   no: 3,
      //   Label: "favorite",
      //   name: "favorite",
      //   id: "favorite",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,

      //   required: true,
      // },
    ],
    BEACH_BASIC_EDIT: [
      {
        no: 1,
        Label: "phone Number",
        name: "phoneNumber",
        id: "phoneNumber",
        type: "text",
        // rule: [
        //   () => ({
        //     validator(_, value) {
        //       // console.log(value);
        //       if (value && /^\d{10}$/.exec(value)) {
        //         return Promise.resolve();
        //       }
        //       if (!value) {
        //         return Promise.resolve();
        //       }
        //       return Promise.reject(
        //         new Error("Please Enter valid mobile number")
        //       );
        //     },
        //   }),
        // ],
        width: 9,
        placeholder: "Enter phoneNumber",
        // required: true,
      },
      {
        no: 2,
        Label: "cell Reception",
        name: "cellReception",
        id: "cellReception",
        type: "select",
        option: [
          { id: "Weak", label: "Weak", value: "weak" },
          { id: "Strong", label: "Strong", value: "strong" },
          { id: "Average", label: "Average", value: "average" },
          { id: "Medium", label: "Medium", value: "medium" },
          { id: "Poor", label: "Poor", value: "poor" },
          // {
          //   id: "Not Available",
          //   label: "Not Available",
          //   value: "Not Available",
          // },
          // { id: "private", label: "private", value: "private" },
        ],
        width: 9,
        placeholder: "Enter cell Reception",
        // required: true,
      },
      {
        no: 3,
        Label: "hours",
        name: "hours",
        id: "hours",
        width: 4,
        step: "0.01",
        type: "text",
        placeholder: "Enter hours",
        // required: true,
      },
      {
        no: 2,
        Label: "size",
        name: "size",
        id: "size",
        width: 4,
        step: "0.0001",
        type: "number",
        placeholder: "Enter size",
        Other: {
          addonAfter: (
            <Form.Item name="unit" noStyle>
              <Select
                style={{
                  width: 110,
                }}
                // defaultValue={"Miles"}
              >
                <Select.Option value="miles">Miles</Select.Option>
                <Select.Option value="kilometers">Kilometers</Select.Option>
                <Select.Option value="meters">Meters</Select.Option>
                <Select.Option value="feet">Feet</Select.Option>
              </Select>
            </Form.Item>
          ),
        },
        // required: true,
      },
      {
        no: 2,
        Label: "parking",
        name: "parking",
        id: "parking",
        width: 3,
        type: "switch",
        placeholder: "Enter parking",
        // required: true,
      },
      {
        no: 2,
        Label: "entry Fee",
        name: "entryFee",
        id: "entryFee",
        width: 3,
        type: "switch",
        placeholder: "Enter entryFee",
        // required: true,
      },
      {
        no: 2,
        Label: "private or Public",
        name: "privatePublic",
        id: "privatePublic",
        width: 4,
        type: "select",
        option: [
          { id: "public", label: "public", value: "public" },
          { id: "private", label: "private", value: "private" },
        ],
        placeholder: "Enter private or Public",
        // required: true,
      },
      {
        no: 2,
        Label: "sand Color",
        name: "sandColor",
        id: "sandColor",
        width: 12,
        type: "text",
        placeholder: "Enter sand Color",
        // required: true,
      },
      {
        no: 2,
        Label: "sand Texture",
        name: "sandTexture",
        id: "sandTexture",
        width: 12,
        type: "text",
        placeholder: "Enter sand Texture",
        // required: true,
      },

      // {
      //   no: 2,
      //   Label: "Address",
      //   name: "address",
      //   id: "address",
      //   // width: 24,
      //   type: "text",
      //   placeholder: "Enter Address",
      //   // required: true,
      // },
    ],
    BEACH_SUMMARY_EDIT: [
      {
        no: 1,
        Label: "Content Source",
        name: "contentSource",
        id: "contentSource",
        type: "radio",
        placeholder: "",
        // required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "AI-Generated",
            value: "AI-Generated",
          },
          {
            id: 2,
            Label: "Hand-Written",
            value: "Human-Written",
          },
        ],
      },
      {
        no: 2,
        Label: "introduction",
        name: "introduction",
        id: "introduction",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter introduction",
        // required: true,
      },
      {
        no: 3,
        Label: "summary",
        name: "summary",
        id: "summary",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter summary",
        // required: true,
      },
      {
        no: 4,
        Label: "activities",
        name: "activities",
        id: "activities",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter activities",
        // required: true,
      },
      {
        no: 5,
        Label: "amenities",
        name: "amenities",
        id: "amenities",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter amenities",
        // required: true,
      },
      {
        no: 6,
        Label: "attraction",
        name: "attraction",
        id: "attraction",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter attraction",
        // required: true,
      },
      {
        no: 7,
        Label: "disability Access",
        name: "disabilityAccess",
        id: "disabilityAccess",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter disabilityAccess",
        // required: true,
      },
      {
        no: 8,
        Label: "fun Fact",
        name: "funFact",
        id: "funFact",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter funFact",
        // required: true,
      },
      {
        no: 9,
        Label: "hotel",
        name: "hotel",
        id: "hotel",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter hotel",
        // required: true,
      },
      {
        no: 10,
        Label: "parking",
        name: "parking",
        id: "parking",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter parking",
        // required: true,
      },
      {
        no: 11,
        Label: "restaurant",
        name: "restaurant",
        id: "restaurant",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter restaurant",
        // required: true,
      },

      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
    ],
    BEACH_SETTING: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Description",
        required: true,
      },
      // {
      //   no: 3,
      //   Label: "H1 tag",
      //   name: "h1",
      //   id: "h1",
      //   type: "text",
      //   placeholder: "Enter H1 tag",
      //   required: true,
      // },
      {
        no: 3,
        Label: "keywords",
        name: "keywords",
        id: "keywords",
        type: "text",
        placeholder: "Enter keywords with separated by ', '",
        // required: true,
      },
    ],
    BEACH_LINK_PHOTO_UPLOAD: [
      {
        no: 1,
        Label: "Photographer Name",
        name: "photographerName",
        id: "photographerName",
        type: "text",
        placeholder: "Enter Photographer Name",
        required: true,
      },
      {
        no: 2,
        Label: "Photographer Link",
        name: "photographerLink",
        id: "photographerLink",
        type: "text",
        placeholder: "Enter Photographer Link",
        required: true,
      },
      {
        no: 3,
        Label: "Photo Link",
        name: "imageUrl",
        id: "imageUrl",
        type: "text",
        placeholder: "Enter Photo Link",
        required: true,
        // rule: [
        //   {
        //     // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
        //     pattern:
        //       /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
        //     message: "Enter valid drone Video url",
        //   },
        // ],
        extraBelow: (
          <>
            <Form.Item shouldUpdate className="w-full ">
              {({ getFieldValue }) => {
                const imageUrl = getFieldValue("imageUrl");

                // Customize the message or component to display based on validation
                if (!imageUrl) {
                  return null;
                }

                return (
                  <Col xs={24} className="flex justify-center items-center">
                    <div className=" aspect-video w-[400px] flex items-center justify-center">
                      <Image src={imageUrl} />
                    </div>
                  </Col>
                );
              }}
            </Form.Item>
          </>
        ),
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Name",
        required: true,
      },
      {
        no: 2,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter Email",
        required: true,
      },
      {
        no: 3,
        Label: "Role",
        name: "role",
        id: "role",
        type: "select",
        option: ROLES_ARRAY,
        placeholder: "Select Role",
        required: true,
      },
      {
        no: 2,
        Label: "Password",
        name: "password",
        id: "password",
        type: "password",
        placeholder: "Enter Password",
        required: true,
      },
    ],
    BLOG_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 10,
        Label: "Meta Title",
        name: "metaTitle",
        id: "metaTitle",
        type: "text",
        placeholder: "Meta Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 11,
        Label: "Meta Description",
        name: "metaDescription",
        id: "metaDescription",
        type: "textarea",
        placeholder: "Enter Meta Description",
        required: true,
      },
      {
        no: 12,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        autoFocus: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tags",
        name: "tags",
        id: "tags",
        type: "select",
        placeholder: "Select Tag",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Alt",
      //   name: "alt",
      //   id: "alt",
      //   type: "text",
      //   placeholder: "Enter Alt",
      //   required: true,
      // },
      // {
      //   no: 8,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime: "HH:mm:ss",
      //   format: "YYYY-MM-DD HH:mm:ss",
      //   // required: true,
      // },
    ],
    EDIT_BLOG_MODAL: [
      {
        no: 0,
        // Label: "Image",
        // name: "image",
        id: "imagePreview",
        type: "ImageCenterPreview",
        // placeholder: " ",
        // required: false,
        image: "image",
        // acceptFormat: "image/*",
      },
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 10,
        Label: "Meta Title",
        name: "metaTitle",
        id: "metaTitle",
        type: "text",
        placeholder: "Meta Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 11,
        Label: "Meta Description",
        name: "metaDescription",
        id: "metaDescription",
        type: "textarea",
        placeholder: "Enter Meta Description",
        required: true,
      },
      {
        no: 12,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        autoFocus: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tags",
        name: "tags",
        id: "tags",
        type: "select",
        placeholder: "Select Tag",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Alt",
      //   name: "alt",
      //   id: "alt",
      //   type: "text",
      //   placeholder: "Enter Alt",
      // },
      // {
      //   no: 9,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime:'HH:mm:ss',
      //   format:"YYYY-MM-DD HH:mm:ss",
      //   required: true,
      //   disabled:true,
      // },
    ],
    SHARK_SPECIES_MODAL: [
      {
        no: 1,
        Label: "Shark Species",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Shark Species",
        required: true,
        autoFocus: true,
      },
      {
        no: 3,
        Label: "Select Country",
        name: "countryIds",
        id: "countryIds",
        type: "select",
        placeholder: "Select Country",
        // required: true,
        option: [],
        mode: "multiple",
      },
      // {
      //   no: 2,
      //   Label: "Select Country ",
      //   name: "Slector",
      //   id: "selector",
      //   type: "citystatecountry",
      //   // required: true,
      //   RequiredObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: false,
      //     islandId: false,
      //   },
      //   VisibleObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //     islandId: false,
      //   },
      //   fieldFilter: ["Country"],
      // },
      {
        no: 3,
        Label: "Shark Family",
        name: "sharkFamily",
        id: "sharkFamily",
        type: "select",
        placeholder: "Select Shark Family",
        required: true,
        option: sharkFamilies,
        // mode: "multiple",
      },
      {
        no: 4,
        Label: "Description",
        name: "description",
        id: "description",
        type: "SimpleRichTextArea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 5,
        Label: "Size Summary",
        name: "sizeSummary",
        id: "sizeSummary",
        type: "SimpleRichTextArea",
        placeholder: "Enter Size Summary",
        // required: true,
      },
    ],
    SHARK_ATTACKS_MODAL: [
      {
        no: 1,
        Label: "Shark Species",
        name: "sharkSpeciesId",
        id: "sharkSpeciesId",
        type: "select",
        placeholder: "Select Shark Species",
        required: true,
        option: [],
        width: 8,
        // mode: "multiple",
      },
     
      {
        no: 3,
        Label: "Type",
        name: "type",
        id: "type",
        type: "select",
        placeholder: "Select Type",
        required: true,
        option: sharkTypes,
        width: 8,
        // mode: "multiple",
      },
      {
        no: 4,
        Label: "Activity",
        name: "activity",
        id: "activity",
        type: "select",
        placeholder: "Select Activity",
        required: true,
        option: activities,
        width: 8,
        // mode: "multiple",
      },
      {
        no: 6,
        Label: "Victim",
        name: "victims",
        id: "victims",
        // menuLabel:"FAQ",
        type: "multifield2",
        required: true,
        menu: "VICTIMS_DATA",
        autoFocus: true,
        // width: 16,
      },
      // {
      //   no: 5,
      //   Label: "Victims",
      //   name: "victims",
      //   id: "victims",
      //   type: "text",
      //   placeholder: "Victims",
      //   required: true,
      //   autoFocus: true,
      //   width: 16,
      // },   
      {
        no: 2,
        Label: "Beach",
        name: "AllBeachId",
        id: "AllBeachId",
        type: "select",
        placeholder: "Select Beach",
        required: true,
        option: [],
        width: 8,
        // mode: "multiple",
      }, 
      // {
      //   no: 4,
      //   Label: "Total Victims",
      //   name: "victimCount",
      //   id: "victimCount",
      //   type: "text",
      //   placeholder: "Enter Total Victims",
      //   required: true,
      //   width: 12,
      // },
      {
        no: 4,
        Label: "Injury",
        name: "injury",
        id: "injury",
        type: "text",
        placeholder: "Enter injury",
        required: true,
        width: 16,
      },
      {
        no: 5,
        Label: "Shark Size",
        name: "sharkSize",
        id: "sharkSize",
        type: "text",
        placeholder: "Enter Shark Size",
        // width: 12,
        // required: true,
      },
     
     
      {
        no: 6,
        Label: "latitude",
        name: "latitude",
        id: "latitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -90 &&
                parseFloat(value) <= 90
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid latitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter latitude",
        // required: true,
      },
      {
        no: 7,
        Label: "longitude",
        name: "longitude",
        id: "longitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -180 &&
                parseFloat(value) <= 180
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid longitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter longitude",
        // required: true,
      },
      {
        no: 2,
        Label: "Fatal",
        name: "fatal",
        id: "fatal",
        type: "radio",
        placeholder: "",
        required: true,
        width: 8,
        // defaultValue:true,
        option:[
          {
            id:1,
            Label:'Yes',
            value:true,
          },
          {
            id:2,
            Label:'No',
            value:false,
          },
        ]
      },
      // {
      //   no: 8,
      //   Label: "Notes",
      //   name: "notes",
      //   id: "notes",
      //   type: "text",
      //   placeholder: "Enter notes",
      //   required: true,
      // },
    ],
    VICTIMS_DATA: [
      {
        no: 1,
        Label: "Victim Name",
        name: "victimName",
        id: "victimName",
        type: "text",
        placeholder: "Enter Victim Name",
        required: true,
        col: 8,
      },
      {
        no: 2,
        Label: "Age",
        name: "age",
        id: "age",
        type: "text",
        placeholder: "Enter Age",
        required: true,
        col: 8,
      },
      {
        no: 4,
        Label: "Gender",
        name: "sex",
        id: "sex",
        type: "select",
        placeholder: "Select Gender",
        required: true,
        option:[
          {
            id:1,
            Label:'Male',
            value:'Male',
          },
          {
            id:2,
            Label:'Female',
            value:'Female',
          },
        ],
        col: 6,
        // mode: "multiple",
      },
     
    ],
  },
  API: {
    login: {
      type: "POST",
      endpoint: "/admin/user/login",
    },
    signUp: { type: "POST", endpoint: "/user/signup" },
    getMe: { type: "GET", endpoint: "/user/me" },
    overview: { type: "GET", endpoint: "/admin/overview" },
    Analytics: {
      InternAnalytics: {
        type: "GET",
        endpoint: "/admin/general/interns-analytics",
      },
    },
    BeachWeight: {
      type: "GET",
      endpoint: "/admin/beachWeight/getForOneBeach/:beachId",
    },
    Beach: {
      getAll: {
        type: "GET",
        endpoint: "/admin/beachMain",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachMain/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachMain/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachMain/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/beachMain/",
      },
    },
    Listicle: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesMain/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/listiclesMain/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesMain/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesMain/",
      },
    },
    Listicle_Beaches: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain/:listicleId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesBeach/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/listiclesBeach/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesBeach/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesBeach/",
      },
    },
    Country: {
      getAll: {
        type: "GET",
        endpoint: "/admin/countries",
      },
      create: {
        type: "POST",
        endpoint: "/admin/countries/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/countries/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/countries/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/countries/",
      },

      getOneBySlug: {
        type: "GET",
        endpoint: "/countries/getBySlug/",
      },
      updateRank: {
        type: "PATCH",
        endpoint: "/admin/countries/rank/",
      },
    },
    State: {
      getAll: {
        type: "GET",
        endpoint: "/admin/states",
      },
      create: {
        type: "POST",
        endpoint: "/admin/states/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/states/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/states/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/states/",
      },
      updateRank: {
        type: "PATCH",
        endpoint: "/admin/states/rank/",
      },
    },
    City: {
      getAll: {
        type: "GET",
        endpoint: "/admin/cities",
      },
      create: {
        type: "POST",
        endpoint: "/admin/cities/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/cities/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/cities/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/cities/",
      },
      updateRank: {
        type: "PATCH",
        endpoint: "/admin/cities/rank/",
      },
    },
    Island: {
      getAll: {
        type: "GET",
        endpoint: "/admin/islands",
      },
      create: {
        type: "POST",
        endpoint: "/admin/islands/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/islands/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/islands/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/islands/",
      },
      updateRank: {
        type: "PATCH",
        endpoint: "/admin/islands/rank/",
      },
    },
    BeachUser: {
      getAll: {
        type: "GET",
        endpoint: "/user/beach/tag",
      },
      create: {
        type: "POST",
        endpoint: "/admin/user/beach/tag/",
      },
      update: {
        type: "PATCH",
        endpoint: "/user/beach/tag/status/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/user/beach/tag/",
      },
      getOne: {
        type: "GET",
        endpoint: "/user/beach/tag/",
      },
    },
    Users: {
      getAll: {
        type: "GET",
        endpoint: "/admin/user",
      },
      create: {
        type: "POST",
        endpoint: "/admin/user",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/user/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/user/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/user/:id",
      },
    },
    Category: {
      getAll: {
        type: "GET",
        endpoint: "/admin/category",
      },
      create: {
        type: "POST",
        endpoint: "/admin/category/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/category/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/category/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/category/",
      },
    },
    Tag: {
      getAll: {
        type: "GET",
        endpoint: "/admin/tag",
      },
      create: {
        type: "POST",
        endpoint: "/admin/tag/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/tag/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/tag/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/tag/",
      },
    },
    ListicleTag: {
      getAll: {
        type: "GET",
        endpoint: "/listiclesMain/getUniqueTags",
      },
    },
    Log: {
      getAll: {
        type: "GET",
        endpoint: "/admin/beachLog",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachLog/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachLog/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachLog/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/beachLog/",
      },
    },
    SubscribeEmail: {
      getAll: {
        type: "GET",
        endpoint: "/admin/subscribeEmail",
      },
      create: {
        type: "POST",
        endpoint: "/admin/subscribeEmail/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/subscribeEmail/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/subscribeEmail/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/subscribeEmail/",
      },
    },
    ContactUS: {
      getAll: {
        type: "GET",
        endpoint: "/admin/contactUs",
      },
      create: {
        type: "POST",
        endpoint: "/admin/contactUs/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/contactUs/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/contactUs/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/contactUs/",
      },
    },
    Suggestions: {
      getAll: {
        type: "GET",
        endpoint: "/admin/adminPanel/suggestions",
      },
      create: {
        type: "POST",
        endpoint: "/admin/adminPanel/suggestions/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/adminPanel/suggestions/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/adminPanel/suggestions/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/adminPanel/suggestions/",
      },
    },
    BeachSuggestions: {
      getAll: {
        type: "GET",
        endpoint: "/admin/beach/suggestions",
      },
      create: {
        type: "POST",
        endpoint: "/beach/suggestions/protected",
        // endpoint: "/admin/beach/suggestions/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beach/suggestions/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beach/suggestions/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/beach/suggestions/",
      },
    },
    RedirectUrl: {
      getAll: {
        type: "GET",
        endpoint: "/admin/beachesUrls",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachesUrls/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachesUrls/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachesUrls/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/beachesUrls/",
      },
    },
    listiclesMain: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesMain/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/listiclesMain/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesMain/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesMain/",
      },
    },
    Blog: {
      getAll: {
        type: "GET",
        endpoint: "/admin/blog",
      },
      create: {
        type: "POST",
        endpoint: "/admin/blog/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/blog/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/blog/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/blog/",
      },
    },
    SharkSpecies: {
      getAll: {
        type: "GET",
        endpoint: "/admin/shark-species",
      },
      create: {
        type: "POST",
        endpoint: "/admin/shark-species/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/shark-species/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/shark-species/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/shark-species/",
      },
    },
    SharkAttacks: {
      getAll: {
        type: "GET",
        endpoint: "/admin/shark-attacks",
      },
      create: {
        type: "POST",
        endpoint: "/admin/shark-attacks/",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/shark-attacks/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/shark-attacks/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/shark-attacks/",
      },
    },
    Flicker: {
      type: "GET",
      endpoint: "/admin/image/flicker",
    },
    SERP: {
      type: "POST",
      endpoint: "/admin/serpPhoto",
    },
    Unsplash: {
      type: "GET",
      endpoint: "/admin/image/flicker",
    },
    getAllCountry: {
      type: "GET",
      // endpoint: "/admin/countries",
      endpoint: "/admin/countries?isSearch=1",
      // endpoint: "/admin/beachMain/getAllCountry",
    },
    getAllState: {
      type: "GET",
      endpoint: "/admin/states?isSearch=1&countryId=",
    },
    getAllIslands: {
      type: "GET",
      endpoint: "/admin/islands?isSearch=1&countryId=",
    },
    getAllCity: {
      type: "GET",
      endpoint:
        "/admin/cities?isSearch=1&countryId=:countryId&stateId=:stateId",
    },
    getAnalyticsAll: {
      type: "GET",
      endpoint: "/admin/general/analytics/country/state/city/beach",
    },
    getBeachBasicDetails: {
      type: "GET",
      endpoint: "/admin/beachBasicDetails/beach/:AllBeachId",
    },
    BeachBasicDetails: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachBasicDetails/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachBasicDetails",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachBasicDetails/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachBasicDetails/:id",
      },
    },
    BeachSummary: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachDescription/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachDescription",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachDescription/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachDescription/:id",
      },
    },
    BeachMain: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachMain/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachMain",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachMain/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachMain/:id",
      },
    },
    BeachDataPoints: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachCategory/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachCategory",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachCategory/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachCategory/:id",
      },
    },
    BeachSEO: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachSEO",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachSEO",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/beachSEO/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachSEO/:id",
      },
    },
    BeachPhoto: {
      IndexUpdate: {
        type: "PATCH",
        endpoint: "/admin/image/indexUpdate/",
      },
      DeletePhoto: {
        type: "POST",
        endpoint: "/admin/image/remove",
      },
      AddPhoto: {
        type: "POST",
        endpoint: "/admin/image",
      },
      AddPhotoBulk: {
        type: "BULKPOST",
        endpoint: "/admin/image",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/image/:id",
      },
    },
    // BeachPhoto: {
    //   getAll: {
    //     type: "GET",
    //     endpoint: "/admin/beachMain",
    //   },
    //   create: {
    //     type: "POST",
    //     endpoint: "/admin/beachMain",
    //   },
    //   update: {
    //     type: "PATCH",
    //     endpoint: "/admin/beachMain/",
    //   },
    //   delete: {
    //     type: "DELETE",
    //     endpoint: "/admin/beachMain/",
    //   },
    //   getOne: {
    //     type: "GET",
    //     endpoint: "/admin/beachMain/",
    //   },
    // },
  },
};
export default CONSTANTS;
